<template>
    <div>
  <!-- Start -->
  <section class="relative md:py-24 py-16 md:pt-0 pt-0 bg-gray-50 dark:bg-slate-800" id="features">
            <div class="container">
                <div class="grid grid-cols-1 justify-center">
                    <div class="relative z-1">
                        <div class="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                            <div class="lg:col-start-2 lg:col-span-10">
                                <div class="relative">
                                    <img src="../assets//images//cta.jpg" class="rounded-md shadow-lg" alt="">
                                    <div class="absolute bottom-2/4 translate-y-2/4 end-0 start-0 text-center">
                                        <a href="#!" @click="toggle" data-type="youtube" data-id="yba7hPeTSjk" class="lightbox lg:size-20 size-16 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white hover:bg-violet-600 text-violet-600 hover:text-white duration-500 ease-in-out mx-auto">
                                            <i class="mdi mdi-play inline-flex items-center justify-center text-3xl"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content md:mt-8">
                            <div class="text-center justify-center">                                
                                <div class="section-title">
                                    <h3 class="text-white/50">¡No te lo pierdas!</h3>
                                    <h5 class="md:text-2xl text-xl font-medium text-white mt-2">                                         
                                        Dale play al video y embárcate en un recorrido visual que te inspirará a conocer más sobre Yucatán y su patrimonio cultural.</h5>
                                </div>                                       
                            </div>
                        </div>
                    </div>
                </div>
                <!--end row -->
            </div>
            <!--end container-->

            <div class="absolute bottom-0 end-0 start-0 h-4/5 md:h-2/3 bg-violet-600"></div>
        </section>
        <!-- End -->
        <div :class="isActive ? 'fixed' : 'hidden' " class="bg-black/[0.9] top-0 left-0 bottom-0 w-[100%] h-[100%] z-999">
                <div class="h-[100%] flex items-center justify-center">
                    <div class="relative inline-block">
                        <iframe :src="videoUrl" ref="videoFrame" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" width="700" height="500" frameborder="0"></iframe>
                        <a class="mdi mdi-close text-white absolute top-1 right-1 text-xl" @click="toggle"></a>
                    </div>
                </div>
          </div>
    </div>
</template>

<script>
    export default {
        name: "ctaVue",
        data() {
            return {
                isActive: false,    
                videoUrl: "http://localhost:8081/redmy.mp4?autoplay=1"           
            }
        },
        watch: {
            isActive(newVal) {
                if (!newVal) {
                    this.stopVideo();
                } else {
                    this.startVideo();
                }
            }
        },
        methods: {
            toggle(){
                if(!this.isActive){
                    this.isActive = true;
                }else{
                    this.isActive = false;
                }
            },
            stopVideo() {
                const iframe = this.$refs.videoFrame;
                iframe.src = '';
            },
            startVideo() {
                const iframe = this.$refs.videoFrame;
                iframe.src = this.videoUrl;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>