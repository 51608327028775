 <template>
    <div>
 <!-- Start -->
 <section class="relative md:py-24 py-16" id="about">
    <div class="container mx-auto">
        <div class="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div class="lg:col-span-5">
                <img src="../assets/images/about.jpg" class="rounded-lg shadow-lg relative" alt="">
            </div><!--end col-->

            <div class="lg:col-span-7">
                <div class="lg:ms-7">
                    <h3 class="mb-4 md:text-2xl text-xl font-medium">¡Bienvenidos a la Red de Museos de Yucatán!</h3>

                    <p class="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">Somos tu guía completa para descubrir la riqueza cultural de nuestra región. Proporcionamos información detallada sobre todos los museos de Yucatán, para que puedas ubicarlos fácilmente, conocer sus temas y exposiciones, así como sus horarios de apertura y mucho más. ¡Sumérgete en el fascinante mundo de la historia y el arte de Yucatán con nosotros!</p>
                
                    <div class="relative mt-8">
                        <router-link to="/aboutus" class="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md">Más<i class="uil uil-arrow-right"></i></router-link>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end grid-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->
    </div>
 </template>
 
 <script>
 import feather from 'feather-icons'

    export default {
        name: "AboutUs",
        data() {
            return {
                
            }
        },
        mounted() {
        feather.replace();
        }
    }
 </script>
 
 <style lang="scss" scoped>
 
 </style>