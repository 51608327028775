import { createWebHistory, createRouter } from "vue-router";
import index from "@/pages/index.vue";
// import About from "@/views/About.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: index,
  },
  {
    path: "/index-two",
    name: "IndexTwo",
    component: () => import('../pages/index-two.vue'),
  },
  {
    path: "/index-three",
    name: "IndexThree",
    component: () => import('../pages/index-three.vue'),
  },
  {
    path: "/index-four",
    name: "IndexFour",
    component: () => import('../pages/index-four.vue'),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import('../components/login.vue'),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import('../components/Signup.vue'),
  },
  {
    path: "/reset-pass",
    name: "ResetPass",
    component: () => import('../components/reset-password.vue'),
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: () => import('../pages/AboutUs.vue'),
  },
  {
    path: "/services",
    name: "Services",
    component: () => import('../pages/Services.vue'),
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: () => import('../pages/Blogs.vue'),
  },
 
  {
    path: "/team",
    name: "Team",
    component: () => import('../pages/Team-Detail.vue'),
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () => import('../pages/Pricing.vue'),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import('../pages/ContactUs.vue'),
  },
  {
    path: "/blog-detail",
    name: "Blog-Detail",
    component: () => import('../pages/BlogDetail.vue'),
  },


 
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;