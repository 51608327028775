<template>
    <div>
   <!-- Start -->
   <section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="features">
            <div class="container lg mx-auto">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h3 class="mb-4 md:text-2xl text-xl font-medium">Nuestros Servicios</h3>

                    <p class="text-slate-400 max-w-xl mx-auto">Nuestros servicios estan orientados a mantenerte al tanto de los museos existentes en Yucatán y sus emocionantes actividades.</p>
                </div><!--end grid-->

                <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                    <!-- Content -->
                    <div v-for="item in servicesData" :key="item" class="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div class="relative overflow-hidden text-transparent -m-3">
                            <i data-feather="hexagon" class="size-28 fill-violet-600/5 mx-auto rotate-[30deg]"></i>
                            <div class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                <i :class=item.icon></i>

                            </div>
                        </div>

                        <div class="mt-6">
                            <router-link to="/services"  class="text-lg h5 transition duration-500 ease-in-out hover:text-violet-600">{{item.title}}</router-link>
                            <p class="text-slate-400 transition duration-500 ease-in-out mt-3">{{item.desc}}</p>
                        </div>
                    </div>
                    <!-- Content -->

                
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End  -->
    </div>
</template>

<script>
 import feather from 'feather-icons'

    export default {
        name: "featuresVue",
        data() {
            return {
                servicesData :[
                    {
                        id:1,
                        icon:'uil uil-map-marker',
                        title:'Ubicaciones',
                        desc:"Mostramos la ubicación de todos los museos existentes en el estado para que puedas planificar tu visita de manera óptima, eligiendo cómo llegar a ellos con antelación."
                    },
                    {
                        id:2,
                        icon:'uil uil-image',
                        title:'Exposiciones',
                        desc:'¡Descubre todas las exposiciones que están en exhibición en los museos y elige cuál deseas visitar!'
                    },
                    {
                        id:3,
                        icon:'uil uil-schedule',
                        title:'Horarios y Días de Apertura',
                        desc:'Descubre los horarios de apertura de todos los museos y planifica tu visita con anticipación.'
                    },
                                 
                ]
            }
        },
        components:{

        },
        mounted() {
        feather.replace();
        }
    }
</script>

<style lang="scss" scoped>

</style>