<template>
    <div>

        <footer class="footer bg-slate-950 relative text-gray-200 dark:text-gray-200">    
            <div class="container">
                <div class="grid grid-cols-12">
                    <div class="col-span-12">
                        <div class="py-[60px] px-0">                    
                            <div class="grid grid-cols-1">
                                <div class="text-center">
                                    <img src="../assets/images/logo-icon-64.png" class="block mx-auto" alt="">
                                    <p class="max-w-xl mx-auto text-slate-400 mt-8">La Red de Museos de Yucatán es una organización sin fines de lucro y que fomenta la cultura.</p>
                                </div>

                                <ul class="list-none footer-list text-center mt-8">
                                  
                                    <li class="inline px-2"><router-link  @click="scrollToTop" to="/services" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">Terminos y condiciones</router-link></li>
                                    <li class="inline px-2 mt-[10px]"><router-link to="/aboutus" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">Aviso de privacidad</router-link></li>
                                </ul><!--end icon-->
                            </div><!--end grid-->
                        </div>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->

            <div class="py-[30px] px-0 border-t border-slate-800">
                <div class="container text-center">
                    <div class="grid md:grid-cols-12 items-center">
                        <div class="md:col-span-6">
                            <div class="md:text-start text-center">
                                <p class="text-gray-400">© {{ date }} Redmy. Desarrollado en colaboración con Sedeculta.</p>
                            </div>
                        </div>
    
                        <div class="md:col-span-6 md:mt-0 mt-8">
                            <ul class="list-none md:text-end text-center">
                               
                                <li class="inline"><a href="https://www.facebook.com/shreethemes" target="_blank" class="size-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i class="uil uil-facebook-f align-middle" title="facebook"></i></a></li>
                                <li class="inline"><a href="https://www.instagram.com/shreethemes/" target="_blank" class="size-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i class="uil uil-instagram align-middle" title="instagram"></i></a></li>
                                <li class="inline"><a href="https://twitter.com/shreethemes" target="_blank" class="size-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i class="uil uil-twitter align-middle" title="twitter"></i></a></li>
                                
                            </ul><!--end icon-->
                        </div>
                    </div>
                </div><!--end container-->
            </div>
        </footer><!--end footer-->
        <!-- Footer End -->
    </div>
</template>

<script>
    export default {
        name: "footerVue",
        data(){
        return{
            date:new Date().getFullYear()
        }
    },
    mounted() {
        this.scrollToTop()
    },
    methods: {
        scrollToTop() {
    window.scrollTo(0,0);
  }
    },
    }
</script>

<style lang="scss" scoped>

</style>